import "config"

import "controllers/member"
import "@hotwired/turbo-rails"

import "helpers/stream_actions"
import "helpers/turbo_frames"

import { registerCustomAlignElements } from "helpers/trix"
registerCustomAlignElements()

import "player.style/sutro"
import "player.style/sutro-audio"
