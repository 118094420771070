import "@mux/mux-player"
import "player.style/sutro"

import { Controller } from "@hotwired/stimulus"
import http from "helpers/http"

const MUX_TOKEN_EXPIRED_ERROR = 2403210

export default class extends Controller {
  static values = { signedTokenUrl: String }

  connect() {
    this.element.addEventListener("error", this.#error.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("error", this.#error)
  }

  #error(event) {
    this.currentPlaybackTime = this.element.currentTime

    if (event.detail?.muxCode === MUX_TOKEN_EXPIRED_ERROR) {
      this.#refreshToken()
    }
  }

  #refreshToken() {
    http.post(this.signedTokenUrlValue).json().then(response => {
      this.signedToken = response.signed_token
      this.#resume()
    })
  }

  #resume() {
    this.element.currentTime = this.currentPlaybackTime
    this.element.play()
  }

  set signedToken(token) {
    const videoSrc = new URL(this.element.src)
    videoSrc.searchParams.set("token", token)

    this.element.src = videoSrc.toString()
  }
}
